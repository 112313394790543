import React from 'react';
import Select from './Select';
import {byLabel} from '../services/Utils';

const SelectProduct = ({handleChange, onBlur, value, validation, label = "Product", defaults = {}}) => {
    const productLabels = () => Object.keys(defaults.products).map(key => ({key, label: defaults.products[key].label}));
    return (
        <Select id="product" label={label} value={value} onBlur={onBlur} validation={validation} handleChange={handleChange}>
            <option value=""/>
            {productLabels().sort(byLabel).map(product => <option value={product.key}>{product.label}</option>)}
        </Select>
    )
}

export default SelectProduct;