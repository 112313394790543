import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Environment from '../containers/Environment';
import './EnvironmentList.scss';

const EnvironmentList = ({
  filteredEnvironments,
  totalCost,
  actions,
  loading,
  firstLoad,
  cloneRef
}) => {
  const itemWord =
    filteredEnvironments.length === 1 ? 'environment' : 'environments';

  useEffect(() => {
    if (firstLoad) {
      actions.fetchEnvironments();
    }
  });

  return (
    <ul className="env-list">
      <span className="counter">
        <strong>{filteredEnvironments.length || (loading ? '' : 'No')}</strong>{' '}
        {itemWord} {loading ? 'Loading...' : ''}
        {loading ? (
          ''
        ) : (
          <div>
            Estimated Total Monthly Cost (720h):{' '}
            <span style={{ color: 'orange' }}>USD {totalCost}</span>
          </div>
        )}
      </span>

      {filteredEnvironments.map((envUrl) => (
        <Environment key={envUrl} envUrl={envUrl} cloneRef={cloneRef} />
      ))}
    </ul>
  );
};

EnvironmentList.propTypes = {
  actions: PropTypes.object.isRequired
};

export default EnvironmentList;
