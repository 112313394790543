import React from 'react';
import { login, configureHub } from '../services/auth';
import { Redirect } from 'react-router-dom';
import './LoginForm.scss'
class LoginForm extends React.Component {

  constructor(props) {
    super(props);
    configureHub(async ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
          await data.signInUserSession;
          this.props.login(data);
          break;
        default:
          break;
      }
    })
  }

  render() {
    return <div className='loginForm'>
      <div className='loginBtn'>
        <div className="loginFormBtnParent">
          <div className='btnLoginContent'>
            { this.props.isNotAuthenticated ?
                <div className="loginBox">
                    <img src="https://www.tecsinapse.com.br/wp-content/themes/tecsinapse/img/logo.svg" alt=""/>
                    <h1>TecEnv 2.0</h1>
                    <button onClick={login}>Login<img src="google-logo.svg" className='google' alt=""/>
                    </button>
                </div> :
                <Redirect to={{ pathname: "/" }} />
            }
          </div>
        </div>
      </div>
    </div>;
  }
}

export default LoginForm;
