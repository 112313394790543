import Amplify, { Auth, Hub } from 'aws-amplify';

Amplify.configure({
    Auth: {
        // REQUIRED - Amazon Cognito Region
        region: 'us-east-1',

        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: 'us-east-1_jg0iJyoN1',

        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: '2jp64l22h2b4tn6brn7hbvv9fh',
    }
});

const oauth = {
  domain: 'tecenv.auth.us-east-1.amazoncognito.com',
  scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
  redirectSignIn:  process.env.REACT_APP_REDIRECT_URL,
  redirectSignOut: process.env.REACT_APP_REDIRECT_URL,
  responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
};

Auth.configure({ oauth });

export const login = () => {
  Auth.federatedSignIn({provider: 'Google'});
}

export const logout = () => {
  Auth.signOut();
}

export const refresh = async (oldSession, login) => {
  try {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const currentSession = await Auth.currentSession();
    cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
      login({...oldSession, signInUserSession: session});
      const redirect_url = window.location.href
      window.location.href = redirect_url;
    });
  } catch (e) {
    console.log('Unable to refresh Token', e);
  }
}

export const configureHub = (callback) => {
  Hub.listen("auth", callback);
}
