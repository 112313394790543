import React from 'react';
import Select from './Select';

const SelectDomain = ({
  handleChange,
  onBlur,
  value,
  validation,
  label = 'Domain'
}) => {
  return (
    <Select
      id="domain"
      label={label}
      value={value}
      onBlur={onBlur}
      validation={validation}
      handleChange={handleChange}
    >
      <option value="" />
      <option value="bmwbic.com.br">bmwbic.com.br</option>
      <option value="brpportal.com.br">brpportal.com.br</option>
      <option value="ciaudibrasil.com.br">ciaudibrasil.com.br</option>
      <option value="cimbb.com.br">cimbb.com.br</option>
      <option value="dynamoce.com.br">dynamoce.com.br</option>
      <option value="dynamomarketing.com.br">dynamomarketing.com.br</option>
      <option value="dynamooficina.com.br">dynamooficina.com.br</option>
      <option value="dynamopecas.com.br">dynamopecas.com.br</option>
      <option value="dynamopneus.com.br">dynamopneus.com.br</option>
      <option value="dynamovendas.com.br">dynamovendas.com.br</option>
      <option value="portalabraland.com.br">portalabraland.com.br</option>
      <option value="portalbamaq.com.br">portalbamaq.com.br</option>
      <option value="portalbonsai.com.br">portalbonsai.com.br</option>
      <option value="portalcaltabiano.com.br">portalcaltabiano.com.br</option>
      <option value="portalcarrera.com.br">portalcarrera.com.br</option>
      <option value="portaldivena.com.br">portaldivena.com.br</option>
      <option value="portalducati.com.br">portalducati.com.br</option>
      <option value="portalhdb.com.br">portalhdb.com.br</option>
      <option value="portaljlr.com.br">portaljlr.com.br</option>
      <option value="portaljlrvd.com.br">portaljlrvd.com.br</option>
      <option value="portalman.com.br">portalman.com.br</option>
      <option value="portalnissan.com.br">portalnissan.com.br</option>
      <option value="portaltecsinapse.com.br">portaltecsinapse.com.br</option>
      <option value="portaltriumphbrasil.com.br">
        portaltriumphbrasil.com.br
      </option>
      <option value="portalvolvo.com.br">portalvolvo.com.br</option>
      <option value="tecsinapse.com.br">tecsinapse.com.br</option>
      <option value="tecsinapse.com">tecsinapse.com</option>
      <option value="tecsinapse.info">tecsinapse.info</option>
      <option value="tecsinapse.net">tecsinapse.net</option>
      <option value="ufaseguro.com.br">ufaseguro.com.br</option>
      <option value="ufaseguros.com.br">ufaseguros.com.br</option>
      <option value="ufaseguros.com">ufaseguros.com</option>
      <option value="zerobot.com.br">zerobot.com.br</option>
    </Select>
  );
};

export default SelectDomain;
