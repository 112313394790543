import React, {useState} from 'react'
import './NewEnvironment.scss'
import EnvironmentTextInput from './EnvironmentTextInput';
import SelectProduct from '../containers/SelectProduct';
import SelectClient from '../containers/SelectClient';
import SelectDomain from './SelectDomain';
import {
  blankError,
  clientDefaultsError,
  cronExpressionError,
  cronExpressionTooltip,
  invalidEnvironmentsError,
  invalidError,
  lowercaseNumberHyphenError,
  lowercaseNumberDotsHyphenError,
  productClientDefaultsError,
  productDefaultsError,
} from "./Bundle";
import {isKubernetes, validBranch, validCronExpression} from "../services/Utils";

const NewEnvironment = ({createEnvironment, environments, loading, defaults, cloneRef, enableCreateInfraOnSave=false}) => {
  const [newEnvs, setNewEnvs] = useState([]);
  const [validations, setValidations] = useState({});
  const [currentId, setCurrentId] = useState(0);

  const addNewEnv = () => {
    setCurrentId(currentId + 1);
    setNewEnvs([...newEnvs, {id: currentId, turnOffAt: 'H 18 * * *', generatorData: '{}'}]);
  }

  const validate = (env, fieldName, message) => {
    let valid = true;
    if (fieldName === 'name') {
      const value = env[fieldName];
      if (value && (value.match(/[^a-z0-9-]/g) || value === 'master')) {
        valid = false;
      }
    }

    if (fieldName === 'subdomain') {
      const value = env[fieldName];
      if (value && value.match(/[^a-z0-9\-.]/g)) {
        message = lowercaseNumberDotsHyphenError('Sub-domain');
        valid = false;
      }
    }

    if (fieldName === 'branch') {
      const value = env[fieldName];
      if (value && !validBranch(value)) {
        message = invalidError('Branch');
        valid = false;
      }
    }

    if (fieldName === 'client') {
      if (env.client && !defaults.customers[env.client]) {
        message = clientDefaultsError
        valid = false;
      }
    }

    if (fieldName === 'product') {
      if (env.product && !defaults.products[env.product]) {
        message = productDefaultsError
        valid = false;
      }
    }

    if (['client', 'product'].includes(fieldName)) {
      if (env.product && env.client && defaults.customers[env.client]
          && !defaults.customers[env.client].products[env.product]) {
        message = productClientDefaultsError
        valid = false;
      }
    }

    const isTurnOnTurnOff = ['turnOnAt', 'turnOffAt'].includes(fieldName);
    if (isTurnOnTurnOff) {
      const value = env[fieldName];
      if (value && !validCronExpression(value)) {
        valid = false;
      }
    }

    if (!isTurnOnTurnOff) {
      if (!env[fieldName] || !valid) {
        setValidations((validations) => ({
          ...validations,
          [`${env.id}.${fieldName}`]: {valid: false, message: message}
        }))
        return false;
      }
    } else {
      if (!valid) {
        setValidations((validations) => ({
          ...validations,
          [`${env.id}.${fieldName}`]: {valid: false, message: message}
        }))
        return false;
      }
    }

    setValidations((validations) => ({...validations,
      [`${env.id}.${fieldName}`]: {valid: true}}))

    return true;
  }

  const validateProduct = (env) => {
    return validate(env, 'product', blankError('Product'));
  }

  const validateClient = (env) => {
    return validate(env, 'client', blankError('Client'));
  }

  const validateName = (env) => {
    return validate(env, 'name', lowercaseNumberHyphenError('Name'));
  }

  const validateBranch = (env) => {
    return validate(env, 'branch', blankError('Branch'));
  }

  const validateDescription = (env) => {
    return validate(env, 'description', blankError('Description'));
  }

  const validateSubdomain = (env) => {
    return validate(env, 'subdomain', lowercaseNumberHyphenError('Sub-domain'));
  }

  const validateDomain = (env) => {
    return validate(env, 'domain', blankError('Domain'));
  }

  const validateTurnOnAt = (env) => {
    return validate(env, 'turnOnAt', cronExpressionError('Turn-on at'));
  }

  const validateTurnOffAt = (env) => {
    return validate(env, 'turnOffAt', cronExpressionError('Turn-off at'));
  }

  const saveAll = () => {
    let valid = true;
    setNewEnvs((newEnvs) => newEnvs.map((e) => ({ ...e, duplicated: false })))
    let portalServerHost = null;
    for (const env of newEnvs) {

      const product = validateProduct(env);
      const client = validateClient(env);
      const name = validateName(env);
      const description = validateDescription(env);
      const domain = validateDomain(env);
      const branch = validateBranch(env);
      const turnOnAt = validateTurnOnAt(env);
      const turnOffAt = validateTurnOffAt(env);
      if (env.product === 'portal-server') {
        portalServerHost = envUrl(env);
      }

      const validEnv = product && client && name && description && domain && branch && turnOnAt && turnOffAt
      const savedEnvsAndNewEnvs = environments.concat(newEnvs.filter(newEnv => newEnv.id !== env.id))
      const duplicatedEnvsByEnvUrl = savedEnvsAndNewEnvs.filter(otherEnv => envUrl(otherEnv) === envUrl(env))
      const duplicatedEnvsByFullname = savedEnvsAndNewEnvs.filter(otherEnv => fullName(otherEnv) === fullName(env))

      const duplicate = (duplicatedEnv) => {
        setNewEnvs((newEnvs) => newEnvs.map(e => e.id === env.id ? { ...env, duplicated: true } : e))
        setNewEnvs((newEnvs) => newEnvs.map(e => e.id === duplicatedEnv.id ? {
           ...duplicatedEnv, duplicated: true } : e))
      }

      for (const duplicatedEnv of duplicatedEnvsByEnvUrl) {
        if (envUrl(duplicatedEnv)) {
          valid = false;
          duplicate(duplicatedEnv);
        }
      }

      for (const duplicatedEnv of duplicatedEnvsByFullname) {
        if (fullName(duplicatedEnv)) {
          valid = false;
          duplicate(duplicatedEnv);
        }
      }

      if (!validEnv) {
        valid = false;
      }
    }

    if (!valid) {
      alert(invalidEnvironmentsError);
      return;
    }

    for (const env of newEnvs) {
      delete env.id;
      delete env.duplicated;
      if (['portal-batch', 'portal-ui', 'incentivos-ui'].includes(env.product) && portalServerHost) {
        env.portalServerHost = portalServerHost;
      }
      if (!isKubernetes(env.product, defaults.products)) {
        delete env.turnOnAt;
        delete env.turnOffAt;
      }
      if (env.name === 'prod') {
        env.protected = true;
      }
      createEnvironment({...env, envUrl: envUrl(env), kubernetes: isKubernetes(env.product, defaults.products)});
    }
    clearAll();
  }

  const envUrl = (env) => {
    if (env.subdomain && env.domain) {
      return `${env.subdomain}.${env.domain}`;
    }
    if (!env.subdomain && env.domain) {
      return env.domain;
    }
    return null;
  }

  const clear = (environment) => {
    setNewEnvs(newEnvs.filter(e => e.id !== environment.id));
  }

  const clearAll = () => {
    setNewEnvs([]);
  }

  const clone = (environment) => {
    setCurrentId(currentId + 1);
    setNewEnvs([...newEnvs, {...environment, id: currentId, duplicated: false}]);
  }
  cloneRef.current = clone;

  const fullName = (environment) => {
    if ('product' in environment && 'client' in environment && 'name' in environment) {
      return `${environment.client}-${environment.product}-${environment.name}`;
    }
    return '';
  }

  return <div className={'new-env'}>
    {loading ? 'Loading...' : <button onClick={() => addNewEnv()}>Add New Environment</button>}

    {newEnvs.map(environment =>
      <div key={environment.id} className="view">
        <div id="fullName">{fullName(environment)}{envUrl(environment) ? ` | ${envUrl(environment)}` : ''} {environment.duplicated ?
          <span className='env-duplicated'>This name or URL already exists!</span> : ''}</div>

        <SelectProduct value={environment.product}
                       validation={validations[`${environment.id}.product`]}
                       onBlur={(e) => validateProduct(environment)}
                       handleChange={(event) => setNewEnvs(newEnvs.map(e => e.id === environment.id ? { ...environment, product: event.target.value } : e))} />

        <SelectClient value={environment.client}
                      validation={validations[`${environment.id}.client`]}
                      onBlur={(e) => validateClient(environment)}
                      handleChange={(event) => setNewEnvs(newEnvs.map(e => e.id === environment.id ? { ...environment, client: event.target.value } : e))} />

        <EnvironmentTextInput id={`name${environment.id}`}
                              label="Name"
                              editMode={false}
                              value={environment.name}
                              maxLength={'12'}
                              validation={validations[`${environment.id}.name`]}
                              className='field'
                              onBlur={(e) => validateName(environment)}
                              onChange={(event) => setNewEnvs(newEnvs.map(e => e.id === environment.id ? { ...environment, name: event.target.value } : e))} />

        <EnvironmentTextInput id={`branch${environment.id}`}
                              label="Branch"
                              editMode={false}
                              value={environment.branch}
                              className='field'
                              validation={validations[`${environment.id}.branch`]}
                              onBlur={(e) => validateBranch(environment)}
                              onChange={(event) => setNewEnvs(newEnvs.map(e => e.id === environment.id ? { ...environment, branch: event.target.value } : e))} />

        <EnvironmentTextInput id={`description${environment.id}`}
                              label="Description"
                              editMode={false}
                              style={{width: '400px'}}
                              value={environment.description}
                              className='field'
                              validation={validations[`${environment.id}.description`]}
                              onBlur={(e) => validateDescription(environment)}
                              onChange={(event) => setNewEnvs(newEnvs.map(e => e.id === environment.id ? { ...environment, description: event.target.value } : e))} />

        <EnvironmentTextInput id={`subdomain${environment.id}`} label="Sub-domain" editMode={false} value={environment.subdomain}
                              validation={validations[`${environment.id}.subdomain`]}
                              className='field'
                              onBlur={(e) => validateSubdomain(environment)}
                              onChange={(event) => setNewEnvs(newEnvs.map(e => e.id === environment.id ? { ...environment, subdomain: event.target.value } : e))} />

        <SelectDomain value={environment.domain}
                      className='field'
                      validation={validations[`${environment.id}.domain`]}
                      onBlur={(e) => validateDomain(environment)}
                      handleChange={(event) => setNewEnvs(newEnvs.map(e => e.id === environment.id ? { ...environment, domain: event.target.value } : e))} />

        { isKubernetes(environment.product, defaults.products) ?
            <div>
              <EnvironmentTextInput id={`turnOn${environment.id}`}
                                    label="Turn-on at"
                                    className='field'
                                    title={cronExpressionTooltip}
                                    editMode={false}
                                    value={environment.turnOnAt}
                                    validation={validations[`${environment.id}.turnOnAt`]}
                                    onBlur={(e) => validateTurnOnAt(environment)}
                                    onChange={(event) => setNewEnvs(newEnvs.map(e => e.id === environment.id ? { ...environment, turnOnAt: event.target.value } : e))} />

              <EnvironmentTextInput id={`turnOff${environment.id}`}
                                    label="Turn-off at"
                                    className='field'
                                    title={cronExpressionTooltip}
                                    editMode={false}
                                    value={environment.turnOffAt}
                                    validation={validations[`${environment.id}.turnOffAt`]}
                                    onBlur={(e) => validateTurnOffAt(environment)}
                                    onChange={(event) => setNewEnvs(newEnvs.map(e => e.id === environment.id ? { ...environment, turnOffAt: event.target.value } : e))} />
            </div>
        : ''}

        <div className='env-separator' />
        {enableCreateInfraOnSave ? <label style={{fontSize: '13px', marginRight: '5px'}}>
          <input id={`runDeployOnCreation${environment.id}`} type="checkbox" checked={true} />
          Create environment at Kubernetes or CloudFront on "Save All"?
        </label> : ''}

        <button className="clone"
                title="Clone environment"
                onClick={() => clone(environment)}>
          <i className="material-icons">file_copy</i>
        </button>
        <button className="destroy"
                title="Delete environment"
                onClick={() => clear(environment)} />
      </div>
    )}
    {newEnvs.length > 0 ? <button onClick={() => saveAll()}>Save All</button> : ''}
  </div>
}

export default NewEnvironment;
