import { connect } from 'react-redux'
import * as EnvironmemtActions from '../actions'
import { bindActionCreators } from 'redux'
import MainSection from '../components/MainSection'

const mapStateToProps = state => ({
  environmentsCount: state.environments.envUrls.length,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(EnvironmemtActions, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MainSection)

