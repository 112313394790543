import { ADD_FILTER, DELETE_FILTER, CLEAR_FILTERS } from '../constants/ActionTypes'
import {defineState} from 'redux-localstore'
import { createBrowserHistory } from 'history';

const defaultState = []

const initialState = defineState(defaultState)('activeFilters')

const setFilterQueryParam = (filters) => {
  const history = createBrowserHistory();
  history.push({
    pathname: '/',
    search: '?filters=' + encodeURI(JSON.stringify(filters)),
  });
}

const history = createBrowserHistory();
if (history.location.search === "?filters=%5B%5D" || history.location.search === "") {
  setFilterQueryParam(initialState);
}

const activeFilters = (state = initialState, action) => {
  switch (action.type) {
    case ADD_FILTER:
      const newStateAfterAdd = [
        ...state,
        {
          id: state.reduce((maxId, filter) => Math.max(filter.id, maxId), -1) + 1,
          key: action.key,
          keyLabel: action.keyLabel,
          term: action.term,
          termLabel: action.termLabel
        }
      ]
      setFilterQueryParam(newStateAfterAdd);
      return newStateAfterAdd;
    case DELETE_FILTER:
      const newStateAfterDelete = state.filter(filter =>
        filter.id !== action.id
      )
      setFilterQueryParam(newStateAfterDelete);
      return newStateAfterDelete;
    case CLEAR_FILTERS:
      return []
    default:
      return state
  }
}

export default activeFilters