import React from 'react';
import Select from './Select';
import {byLabel} from "../services/Utils";

const SelectClient = ({handleChange, onBlur, value, validation, label="Client", defaults={}}) => {
    const customerLabels = () => Object.keys(defaults.customers).map(key => ({key, label: defaults.customers[key].label}));
    return (
        <Select id="client" label={label} value={value} onBlur={onBlur} validation={validation} handleChange={handleChange}>
            <option value="" />
            {customerLabels().sort(byLabel).map(customer => <option value={customer.key}>{customer.label}</option>)}
        </Select>
    )
}

export default SelectClient;