import { createSelector } from 'reselect';

const getActiveFilters = (state) => state.activeFilters;
const getEnvironments = (state) => state.environments;

const filterEnvironment = (environment, filters) => {
  let filtered = true;
  let matched = {};

  for (const filter of filters) {
    if (filter.key === 'oldEnvs') {
      let dateOffset = 24 * 60 * 60 * 1000 * filter.term;
      let dateDaysBefore = new Date();
      dateDaysBefore.setTime(dateDaysBefore.getTime() - dateOffset);
      filtered = !(
        (environment.lastBuild &&
          environment.lastBuild.timestamp > dateDaysBefore.getTime()) ||
        environment.modifiedDate > dateDaysBefore.getTime() ||
        environment.protected === true ||
        environment.kubernetes === false
      );
    }

    if (filter.key === 'platform') {
      if (environment.kubernetes && filter.term === 'k8s') {
        filtered = true;
      } else if (!environment.kubernetes && filter.term === 'cloudfront') {
        filtered = true;
      } else {
        filtered = false;
      }
    }

    if (filter.key === 'protected') {
      filtered = (environment.protected ? 'true' : 'false') === filter.term;
    }

    if (
      environment[filter.key] &&
      environment[filter.key]
        .toString()
        .toLowerCase()
        .indexOf(filter.term.toLowerCase()) >= 0
    ) {
      matched[filter.key] = true;
    } else if (environment[filter.key]) {
      if (!matched[filter.key]) matched[filter.key] = false;
    }
  }

  for (const key in matched) {
    filtered = matched[key] && filtered;
  }

  return filtered;
};

export const getVisibleEnvironments = createSelector(
  [getActiveFilters, getEnvironments],
  (activeFilters, environments) => {
    if (activeFilters.length === 0) {
      return environments.envUrls;
    }

    return environments.envUrls.filter((envUrl) =>
      filterEnvironment(environments.byEnvUrl[envUrl], activeFilters)
    );
  }
);

export const getTotlCostEnvironments = createSelector(
  [getActiveFilters, getEnvironments],
  (activeFilters, environments) => {
    return environments.envUrls
      .filter((envUrl) =>
        filterEnvironment(environments.byEnvUrl[envUrl], activeFilters)
      )
      .reduce(
        (total, envUrl) =>
          total + parseFloat(environments.byEnvUrl[envUrl].cost),
        0
      )
      .toFixed(2);
  }
);
