import { connect } from 'react-redux'
import { createEnvironment } from '../actions'
import NewEnvironment from '../components/NewEnvironment'

const mapStateToProps = (state, ownProps) => ({
  environments: Object.keys(state.environments.byEnvUrl).map(
    envUrl => state.environments.byEnvUrl[envUrl]),
  loading: state.environments.loading,
  defaults: state.environments.defaults,
  cloneRef: ownProps.cloneRef,
})

const mapDispatchToProps = (dispatch) => ({
  createEnvironment: (env) => {
    dispatch(createEnvironment(env))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewEnvironment)
