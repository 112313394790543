import React from 'react'
import { render } from 'react-dom'
import { createStore, applyMiddleware } from 'redux'
import { Provider, connect } from 'react-redux'
import App from './components/App'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import reducer from './reducers'
import './styles/index.scss'
import LoginForm from './containers/LoginForm'
import storeSynchronize from 'redux-localstore'
import thunk from 'redux-thunk'

const api = process.env.REACT_APP_API_URL

const store = createStore(reducer, applyMiddleware(thunk.withExtraArgument({api})))

storeSynchronize(store)

const PrivateRoute = connect(state=>({isAuthenticated: ('signInUserSession' in state.session)}))(({ component: Component, isAuthenticated, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: `/login`, state: { from: props.location } }} />
      )
    }
  />
));

render(
  <Provider store={store}>
    <Router basename="/">
      <Switch>
        <PrivateRoute exact path='/' component={App} />
        <Route path='/login' component={LoginForm} />
        <Route path="*" component={() => <h1>Page not found</h1>} />
      </Switch>
    </Router>
  </Provider>,
  document.getElementById('root')
)
