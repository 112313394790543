import React from 'react'
import PropTypes from 'prop-types'
import './Header.scss'

const Header = ({ profile, actions }) => {
  const changeTheme = (theme) => {   
    document.documentElement.className = '';
    document.documentElement.classList.add(`theme-${theme}`);
  }
  return (<header className="header container">
    <img src="https://www.tecsinapse.com.br/wp-content/themes/tecsinapse/img/logo.svg" alt=""/>
    <div className="session">
      <img className='profile' src={profile.picture} alt=""/>
      <div className='email' alt="">{profile.email}</div>
      <button onClick={actions.logout}><i className="material-icons">exit_to_app</i></button>
      <div className="App__theme-switcher">
        <button onClick={()=>changeTheme("light")}>Light</button>
        <button id="#dark" onClick={()=>changeTheme("dark")}>Dark</button>
      </div>
    </div>
  </header>);
}

Header.propTypes = {
  profile: PropTypes.object.isRequired
}

export default Header;
