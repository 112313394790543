import { connect } from 'react-redux'
import Header from '../components/Header'
import { bindActionCreators } from 'redux'
import * as EnvironmemtActions from '../actions'

const mapStateToProps = state => ({
    profile: EnvironmemtActions.getProfile(state.session)
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(EnvironmemtActions, dispatch)
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Header)
