import { connect } from 'react-redux'
import SelectProduct from '../components/SelectProduct'

const mapStateToProps = (state, ownProps) => ({
  defaults: state.environments.defaults,
  handleChange: ownProps.handleChange,
  onBlur: ownProps.onBlur,
  value: ownProps.value,
  validation: ownProps.validation,
  label: ownProps.label
})

export default connect(
  mapStateToProps
)(SelectProduct)
