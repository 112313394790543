import { connect } from 'react-redux'
import {login, logout} from '../actions'
import LoginForm from '../components/LoginForm'

const mapStateToProps = state => ({
  isNotAuthenticated: !('signInUserSession' in state.session)
})

const mapDispatchToProps = dispatch => ({
  login: (token) => {dispatch(login(token))},
  logout: () => {dispatch(logout())},
})


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginForm)

