import React from 'react'
import ActiveFilter from '../components/ActiveFilter'
import AddFilter from '../components/AddFilter'
import './FilterBar.scss'

const FilterBar = ({ filters, addFilter, deleteFilter }) => {
  return (
    <div className={"container"}>
      <ul className="filters container">
        {filters.map(filter =>
          <li key={filter.id}>
            <ActiveFilter deleteFilter={deleteFilter}
              filterKey={filter.id} label={filter.keyLabel}>
              {filter.termLabel}
            </ActiveFilter>
          </li>
        )}
        <li key={'text'}>
          <AddFilter addFilter={addFilter} />
        </li>
      </ul>
    </div>
  )
}

export default FilterBar;
