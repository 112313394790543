import React from 'react'
import PropTypes from 'prop-types'

const ActiveFilter = ({ filterKey, label, children, deleteFilter }) =>
  <span className={'filter'}>
    <label>
      {label}: {children}
    </label>
    <button className="destroy"
            onClick={() => deleteFilter(filterKey)} />
  </span>


ActiveFilter.propTypes = {
  children: PropTypes.node.isRequired,
  deleteFilter: PropTypes.func.isRequired
}

export default ActiveFilter;
