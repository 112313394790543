export const ADD_ENVIRONMENT = 'ADD_ENVIRONMENT'
export const DELETE_ENVIRONMENT = 'DELETE_ENVIRONMENT'
export const EDIT_ENVIRONMENT = 'EDIT_ENVIRONMENT'
export const REQUEST_ENVIRONMENTS = 'REQUEST_ENVIRONMENTS'
export const RECEIVE_ENVIRONMENTS = 'RECEIVE_ENVIRONMENTS'
export const ADD_FILTER = 'ADD_FILTER'
export const DELETE_FILTER = 'DELETE_FILTER'
export const CLEAR_FILTERS = 'CLEAR_FILTERS'
export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'
export const RECEIVE_BUILD_STATUS = 'RECEIVE_BUILD_STATUS'
