import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as EnvironmentActions from '../actions';
import EnvironmentList from '../components/EnvironmentList';
import { getVisibleEnvironments, getTotlCostEnvironments } from '../selectors';

const mapStateToProps = (state, ownProps) => ({
  filteredEnvironments: getVisibleEnvironments(state),
  totalCost: getTotlCostEnvironments(state),
  loading: state.environments.loading,
  firstLoad: state.environments.firstLoad,
  cloneRef: ownProps.cloneRef
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(EnvironmentActions, dispatch)
});

const VisibleEnvironmentList = connect(
  mapStateToProps,
  mapDispatchToProps
)(EnvironmentList);

export default VisibleEnvironmentList;
