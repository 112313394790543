import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import FilterBar from '../containers/FilterBar'
import VisibleEnvironmentList from '../containers/VisibleEnvironmentList'
import NewEnvironment from '../containers/NewEnvironment'
import { useLocation } from "react-router-dom";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const MainSection = ({ environmentsCount, actions }) => {
  const cloneRef = {
    current: null
  }

  let query = useQuery();
  const initialFilters = JSON.parse(query.get("filters"));

  useEffect(() => {
    if (environmentsCount > 0 && initialFilters) {
      actions.clearFilters();
      for (const filter of initialFilters) {
        actions.addFilter(filter.key, filter.term, filter.keyLabel, filter.termLabel);
      }
    }
  }, [environmentsCount, initialFilters, actions])

  return (
    <section className="main">
      <NewEnvironment cloneRef={cloneRef} />
      { !!environmentsCount && <FilterBar /> }
      <VisibleEnvironmentList cloneRef={cloneRef} />
    </section>
  )
}
MainSection.propTypes = {
  environmentsCount: PropTypes.number.isRequired,
}

export default MainSection;