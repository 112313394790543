import React, { useState } from 'react'
import SelectProduct from '../containers/SelectProduct';
import Select from './Select';
import SelectClient from '../containers/SelectClient';
import EnvironmentTextInput from './EnvironmentTextInput';
import SelectDomain from './SelectDomain';

const AddFilter = ({ addFilter }) => {

  const [key, setKey] = useState('');
  const [term, setTerm] = useState('');
  const [termLabel, setTermLabel] = useState('');
  const [keyLabel, setKeyLabel] = useState('');

  const resolveFieldValue = (e) => {
    if (e.nativeEvent.target.selectedIndex) {
      let index = e.nativeEvent.target.selectedIndex;
      return e.nativeEvent.target[index].text;
    }

    return e.target.value;
  }

  const handleKeyChange = (e) => {
    setKey(e.target.value);
    setKeyLabel(resolveFieldValue(e));
  }

  const handleChange = (e) => {
    setTerm(e.target.value);
    setTermLabel(resolveFieldValue(e));
  }

  const save = () => {
    addFilter(key, term, keyLabel, termLabel);
    setTerm('');
    setTermLabel('');
    setKey('');
    setKeyLabel('');
  }

  const selectKey = () => {
    if (key !== '') {
      return <Select id="key" handleChange={handleKeyChange}>
                <option value="product">Product</option>
                <option value="client">Client</option>
                <option value="name">Env Name</option>
                <option value="description">Description</option>
                <option value="subdomain">Sub-domain</option>
                <option value="domain">Domain</option>
                <option value="branch">Branch</option>
                <option value="createdBy">Owner e-mail</option>
                <option value="platform">Platform</option>
                <option value="oldEnvs">Days without changes</option>
                <option value="protected">Protected from automatic removal</option>
            </Select>
    }
  }

  const selectProduct = () => {
    if (key === 'product') {
      return <SelectProduct handleChange={handleChange} label="" />
    }
  }

  const inputs = () => {
    if (key !== '' && !['product', 'client', 'domain', 'platform', 'protected'].includes(key)) {
      return <EnvironmentTextInput onChange={handleChange} onBlur={save} />
    }
  }

  const selectClient = () => {
    if (key === 'client') {
      return <SelectClient handleChange={handleChange} label="" />
    }
  }

  const selectPlatform = () => {
    if (key === 'platform') {
      return <Select id="platform" label="" handleChange={handleChange}>
        <option value=""/>
        <option value="k8s">Kubernetes</option>
        <option value="cloudfront">CloudFront</option>
      </Select>
    }
  }

  const selectProtected = () => {
    if (key === 'protected') {
      return <Select id="protected" label="" handleChange={handleChange}>
        <option value=""/>
        <option value="true">Yes</option>
        <option value="false">No</option>
      </Select>
    }
  }

  const selectDomain = () => {
    if (key === 'domain') {
      return <SelectDomain handleChange={handleChange} label="" />
    }
  }

  return (
    <span className={'filter'}>
      {selectKey()}
      {key !== '' ? ':' : ''}
      {inputs()}
      {selectProduct()}
      {selectClient()}
      {selectDomain()}
      {selectPlatform()}
      {selectProtected()}
      {key === '' ?
        <span onClick={() => {setKey('product'); setKeyLabel('Product')}}>
          Add Filter +
        </span> :
        <span className='actions'>
          <i onClick={() => save()} className="material-icons">done</i>
          <i onClick={() => setKey('')} className="material-icons">clear</i>
        </span>
      }
    </span>
  )
}

export default AddFilter
