export const isKubernetes = (product, products) => {
    if (!product) return true;
    return !(products[product].frontend || false);
};

export const canEditEnv = (environment, profile) => {
    return true;
};

export const formatDateTimePtBr = date => new Intl.DateTimeFormat('pt-BR', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: 'numeric',
    minute: 'numeric'
}).format(date);

export const validCronExpression = (value) => {
    return !(value.split(' ').length !== 5 || !value.match(/[H,\-*/0-9()]+\s[H,\-*/0-9()]+\s[H,\-*/0-9()]+\s[H,\-*/0-9()]+\s[H,\-*/0-9()]+/g));
};

export const validBranch = (branch) => {
    return branch.match(/^(?!\/|.*([/.]\.|\/\/|@\{|\\\\))[^\040\177\s~^:#?*[]+$/g);
};

export const byLabel = (elem, nextElem) => {
    if (elem.label > nextElem.label) return 1;
    if (elem.label < nextElem.label) return -1;
    return 0;
};