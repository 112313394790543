import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as EnvironmentActions from '../actions'
import Environment from '../components/Environment'

const mapStateToProps = (state, ownProps) => ({
  environment: state.environments.byEnvUrl[ownProps.envUrl],
  defaults: state.environments.defaults,
  profile: EnvironmentActions.getProfile(state.session)
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(EnvironmentActions, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Environment)
