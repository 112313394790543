import React, { useState } from 'react';
import EnvironmentTextInput from './EnvironmentTextInput';
import {
  formatDateTimePtBr,
  validBranch,
  validCronExpression
} from '../services/Utils';
import JenkinsConfigurator from './JenkinsConfigurator';
import { canEditEnv } from '../services/Utils';
import './Environment.scss';
import {
  blankError,
  cronExpressionError,
  cronExpressionTooltip,
  invalidError
} from './Bundle';

const Environment = ({ environment, defaults, actions, cloneRef, profile }) => {
  const [loading, setLoading] = useState(false);

  const cloneToNewEnv = (env) => {
    cloneRef.current({
      product: env.product,
      client: env.client,
      name: env.name,
      branch: env.branch,
      description: env.description,
      subdomain: env.subdomain,
      domain: env.domain,
      turnOnAt: env.turnOnAt,
      turnOffAt: env.turnOffAt
    });
  };
  const generatorData = JSON.parse(environment.generatorData);
  let repoName =
    generatorData && generatorData.repositoryUrl
      ? generatorData.repositoryUrl.split('/')[1].split('.')[0]
      : '';
  let releaseName =
    generatorData && generatorData.releaseName
      ? generatorData.releaseName
      : null;
  return (
    <li className="env">
      {!loading ? (
        <div>
          <div className="view">
            <div className="data">
              <div>
                <span className="audit">
                  <b>Created by: </b>
                  {environment.createdBy}&nbsp;
                  <b>Modified by: </b>
                  {environment.modifiedBy}&nbsp;
                  <b>Created date: </b>
                  {formatDateTimePtBr(environment.createdDate)}&nbsp;
                  <b>Modified date: </b>
                  {formatDateTimePtBr(environment.modifiedDate)}
                </span>
              </div>
              {environment.kubernetes ? (
                <div>
                  Estimated Cost (720h):
                  <span style={{ color: 'orange' }}>
                    {' '}
                    USD {environment.cost}
                  </span>
                </div>
              ) : (
                ''
              )}
              <div>
                <div
                  className="orange"
                  style={{ display: 'inline', marginRight: '5px' }}
                >
                  Name:
                </div>
                {environment.client}-{environment.product}-{environment.name}
                {environment.protected ? (
                  <span style={{ color: 'red' }}>
                    {' '}
                    (protected from automatic removal)
                  </span>
                ) : (
                  ''
                )}
                {environment.generatorData &&
                environment.generatorData !== '{}' &&
                generatorData.envCategory === 'prod' ? (
                  <span style={{ color: 'red', fontWeight: 'bold' }}>
                    {' '}
                    - PRODUCTION ENVIRONMENT
                  </span>
                ) : (
                  ''
                )}
                <br />
                <div
                  className="orange"
                  style={{ display: 'inline', marginRight: '5px' }}
                >
                  Platform:
                </div>
                {environment.kubernetes ? 'Kubernetes' : 'CloudFront'}
                <br />
                <div
                  className="orange"
                  style={{ display: 'inline', marginRight: '5px' }}
                >
                  Branch:
                </div>
                <img src="git_logo.png" alt="git logo" className="smallLogo" />
                <EnvironmentTextInput
                  id={'branch'}
                  autoFocus
                  style={{ display: 'inline', margin: 0, width: '400px' }}
                  onChange={(e) =>
                    actions.editEnvironment(environment.envUrl, {
                      ...environment,
                      branch: e.target.value
                    })
                  }
                  onBlur={(e) => {
                    if (!e.target.value) alert(blankError('Branch'));
                    else if (e.target.value && !validBranch(e.target.value))
                      alert(invalidError('Branch'));
                    else
                      actions.updateEnvironment(
                        environment,
                        'branch',
                        e.target.value
                      );
                  }}
                  value={environment.branch}
                  editMode={true}
                />
                <br />
                <div
                  className="orange"
                  style={{ display: 'inline', marginRight: '5px' }}
                >
                  Description:
                </div>
                <EnvironmentTextInput
                  id={'description'}
                  autoFocus
                  style={{ display: 'inline', margin: 0, width: '550px' }}
                  onChange={(e) =>
                    actions.editEnvironment(environment.envUrl, {
                      ...environment,
                      description: e.target.value
                    })
                  }
                  onBlur={(e) => {
                    if (!e.target.value) alert(blankError('Description'));
                    else
                      actions.updateEnvironment(
                        environment,
                        'description',
                        e.target.value
                      );
                  }}
                  value={environment.description}
                  editMode={true}
                />
                <br />
                {environment.kubernetes ? (
                  <div>
                    <div
                      className="orange"
                      style={{ display: 'inline', marginRight: '5px' }}
                    >
                      Turn-on at:
                    </div>
                    <EnvironmentTextInput
                      id={'turnOnAt'}
                      autoFocus
                      title={cronExpressionTooltip}
                      style={{ display: 'inline', width: '80px' }}
                      onChange={(e) =>
                        actions.editEnvironment(environment.envUrl, {
                          ...environment,
                          turnOnAt: e.target.value
                        })
                      }
                      onBlur={(e) => {
                        if (
                          e.target.value &&
                          !validCronExpression(e.target.value)
                        ) {
                          alert(cronExpressionError('Turn-on at'));
                        } else {
                          actions.updateEnvironment(
                            environment,
                            'turnOnAt',
                            e.target.value
                          );
                        }
                      }}
                      value={environment.turnOnAt || ''}
                      editMode={true}
                    />
                    <br />

                    <div
                      className="orange"
                      style={{ display: 'inline', marginRight: '5px' }}
                    >
                      Turn-off at:
                    </div>
                    <EnvironmentTextInput
                      id={'turnOffAt'}
                      autoFocus
                      title={cronExpressionTooltip}
                      style={{ display: 'inline', width: '80px' }}
                      onChange={(e) =>
                        actions.editEnvironment(environment.envUrl, {
                          ...environment,
                          turnOffAt: e.target.value
                        })
                      }
                      onBlur={(e) => {
                        if (
                          e.target.value &&
                          !validCronExpression(e.target.value)
                        ) {
                          alert(cronExpressionError('Turn-off at'));
                        } else {
                          actions.updateEnvironment(
                            environment,
                            'turnOffAt',
                            e.target.value
                          );
                        }
                      }}
                      value={environment.turnOffAt || ''}
                      editMode={true}
                    />
                  </div>
                ) : (
                  ''
                )}
              </div>
              {environment.useIngress === false ? (
                ''
              ) : (
                <div>
                  <div
                    className="orange"
                    style={{ display: 'inline', marginRight: '5px' }}
                  >
                    URL(s):
                  </div>
                  {!environment.subdomains ? (
                    <a
                      title="Open on a new browser tab"
                      href={'https://' + environment.envUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {environment.envUrl}
                    </a>
                  ) : (
                    ''
                  )}
                  {environment.subdomains &&
                    environment.subdomains.map((subdomain) => (
                      <div>
                        <a
                          title="Open this URL a new browser tab"
                          href={`https://${
                            typeof subdomain == 'string'
                              ? subdomain
                              : subdomain.subdomain
                          }`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {typeof subdomain === 'string'
                            ? subdomain
                            : subdomain.subdomain}
                        </a>
                      </div>
                    ))}
                </div>
              )}
              {repoName && environment.kubernetes ? (
                <div>
                  <div
                    className="orange"
                    style={{ display: 'inline', marginRight: '5px' }}
                  >
                    Release/Build Job:
                  </div>
                  <a
                    title="Open on a new browser tab"
                    href={`https://jenkins.portaltecsinapse.com.br/blue/organizations/jenkins/Tecsinapse%20Github%2F${repoName}/activity?branch=${environment.branch.replace(
                      '/',
                      '%252F'
                    )}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {environment.branch}
                  </a>
                </div>
              ) : (
                ''
              )}
              {environment.lastBuild && environment.lastBuild.result ? (
                <div>
                  <div
                    className="orange"
                    style={{ display: 'inline', marginRight: '5px' }}
                  >
                    Last deploy build:
                  </div>
                  <a
                    title="Open on a new browser tab"
                    href={environment.lastBuild.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {environment.lastBuild.result} {environment.lastBuild.date}{' '}
                    {environment.lastBuild.description}
                  </a>
                </div>
              ) : (
                ''
              )}
              {releaseName ? (
                <div>
                  <a
                    title="Open on a new browser tab"
                    href={`https://github.com/tecenv/tecenv-${releaseName}/commits/master/config.json`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Environment changes
                  </a>
                </div>
              ) : (
                ''
              )}
            </div>
            <div className="destroyView">
              <button
                className="clone"
                title="Clone environment"
                onClick={() => cloneToNewEnv(environment)}
              >
                <i className="material-icons">file_copy</i>
              </button>
              {canEditEnv(environment, profile) ? (
                <button
                  className="env destroy"
                  title="Delete environment"
                  onClick={() => {
                    console.log(environment);
                    if (
                      window.confirm(
                        `ATTENTION: Are you sure you want to delete ${environment.client}-${environment.product}-${environment.name} (${environment.envUrl}) environment?`
                      )
                    ) {
                      setLoading(true);
                      actions.deleteEnvironment(environment, () =>
                        setLoading(false)
                      );
                    }
                  }}
                />
              ) : (
                ''
              )}
            </div>
          </div>
          <div className="jenkins">
            <JenkinsConfigurator
              env={environment}
              defaults={defaults}
              setLoading={setLoading}
              updateEnvironment={actions.updateEnvironment}
              profile={profile}
            >
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={
                  environment.generatorData &&
                  environment.generatorData !== '{}'
                    ? 'https://jenkins.portaltecsinapse.com.br/view/' +
                      environment.jenkinsView.split('/').join('/view/') +
                      '/'
                    : 'https://jenkins.portaltecsinapse.com.br/'
                }
              >
                <img
                  className="jenkinsLogo"
                  alt=""
                  src="jenkins_logo.png"
                  title="View Jenkins jobs"
                />
              </a>
            </JenkinsConfigurator>
          </div>
        </div>
      ) : (
        <h1>Wait, Jenkins is being updated...</h1>
      )}
    </li>
  );
};

export default Environment;
