import React from 'react';
import './JenkinsConfigurator.scss';
import { JsonEditor as Editor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';
import {
  jsonSyntaxInvalidError,
  noCustomerConfiguredDefaultsError
} from './Bundle';
import { isKubernetes, canEditEnv } from '../services/Utils';

class JenkinsConfigurator extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      edit: false,
      jsonEditorMode: Editor.modes.tree,
      generatorData: this.props.env['generatorData'],
      defaults: this.props.defaults,
      isLoaded: !!this.props.defaults
    };

    this.edit = this.edit.bind(this);
    this.save = this.save.bind(this);
    this.cancel = this.cancel.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return (
      (this.props.env !== nextProps.env &&
        this.props.env.modifiedDate < nextProps.env.modifiedDate) ||
      this.state.generatorData !== nextState.generatorData ||
      !this.state.isLoaded ||
      nextState.edit ||
      (this.props.env === nextProps.env && this.state.edit)
    );
  }

  render() {
    if (this.state.isLoaded) {
      if (this.state.edit) {
        return (
          <div className="jenkins">
            <label>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://sites.google.com/tecsinapse.com.br/infra/guias/kubernetes/criacao-ambientes-kubernetes"
              >
                Documentação dos campos abaixo
              </a>
            </label>
            <Editor
              mode={this.state.jsonEditorMode}
              value={JSON.parse(this.state.generatorData) || ''}
              onChange={(value) => {
                this.setState({ generatorData: JSON.stringify(value) });
              }}
            />
            <div className="buttons">
              {canEditEnv(this.props.env, this.props.profile) ? (
                <button
                  className="jenkins-button jenkinsSave"
                  onClick={this.save}
                >
                  SAVE
                </button>
              ) : (
                ''
              )}
              <button
                className="jenkins-button jenkinsCancel"
                onClick={this.cancel}
              >
                CANCEL
              </button>
            </div>
          </div>
        );
      } else {
        this.setFields();
        if (this.props.children) {
          return (
            <div>
              {this.props.children}
              <img
                src="edit_logo.png"
                alt="click to edit"
                onClick={() => this.edit(Editor.modes.tree)}
                className="smallLogo editLogo"
              />
              <span className="editLogoLabel">Tree</span>
              <img
                src="edit_logo.png"
                alt="click to edit"
                onClick={() => this.edit(Editor.modes.code)}
                className="smallLogo editLogo"
              />
              <span className="editLogoLabel">Code</span>
            </div>
          );
        }
      }
    } else {
      this.setState({ isLoaded: !!this.props.defaults });
      return <div>Loading...</div>;
    }
  }

  edit(editorMode = Editor.modes.tree) {
    this.setState({ edit: true });
    this.setState({ jsonEditorMode: editorMode });
  }

  setFields() {
    if (this.state.generatorData != null && this.state.generatorData !== '') {
      try {
        JSON.parse(this.state.generatorData);
      } catch (e) {
        return;
      }
    }

    const generatorDataBeforeDefaults = JSON.parse(this.state.generatorData);
    if (generatorDataBeforeDefaults.envCategory === 'prod') {
      return;
    }

    this.product = this.state.defaults.products[this.props.env.product];
    let portalBatchOrMenuAttributes = {};
    if (
      ['portal-batch', 'portal-menu-server', 'incentivos-batch'].includes(
        this.props.env.product
      )
    ) {
      const dbHost = this.product.dbHost.replace(
        'ENV_NAME',
        this.props.env.name
      );
      if (this.product.dbHost) {
        portalBatchOrMenuAttributes.dbHost = dbHost;
      }
      if (this.product.deploymentToScale) {
        portalBatchOrMenuAttributes.deploymentToScale = dbHost;
      }
      if (this.product.tomcatDataSources) {
        let tomcatDataSources = [...this.product.tomcatDataSources];
        tomcatDataSources.forEach(
          (tomcatDataSource) => (tomcatDataSource.dbHost = dbHost)
        );
        portalBatchOrMenuAttributes.tomcatDataSources = tomcatDataSources;
      }
      if (
        this.props.env.product === 'portal-batch' &&
        this.props.env.portalServerHost
      ) {
        portalBatchOrMenuAttributes.mavenGoals = this.product.mavenGoals.replace(
          'PORTAL-SERVER-HOST',
          this.props.env.portalServerHost
        );
      }
    }
    this.client = this.state.defaults.customers[this.props.env.client];
    if (!this.state.defaults.customers[this.props.env.client]) {
      alert(noCustomerConfiguredDefaultsError(this.props.env.client));
      return;
    }
    this.clientProduct = this.state.defaults.customers[
      this.props.env.client
    ].products[this.props.env.product];
    this.generatorDataObject = {
      ...this.product,
      ...this.client,
      ...this.clientProduct,
      ...portalBatchOrMenuAttributes
    };
    delete this.generatorDataObject.products;
    this.generatorDataObject.jenkinsView += this.props.env.name.replace(
      /-/g,
      '_'
    );
    if (this.generatorDataObject.mavenGoals) {
      this.generatorDataObject.mavenGoals += ` -Dts.env.host=https://${this.props.env.envUrl}/ -Dapp.url=${this.props.env.envUrl} -Dfilter.url=https://${this.props.env.envUrl}`;
    }
    if (this.generatorDataObject.shellName) {
      this.generatorDataObject.domains = [`${this.props.env.domain}`];
      this.generatorDataObject.subdomains = [this.props.env.envUrl];
      this.generatorDataObject.shellScript = `cat <<EOT >> main.tf
terraform {
  backend "s3" {
    bucket = "tec-terraform"
    key    = "${this.props.env.client}-${this.props.env.product}-${
        this.props.env.name
      }/terraform.tfstate"
    region = "us-east-1"
  }
}
EOT
terraform init
NVM_DIR="$HOME/.nvm" && . "$NVM_DIR/nvm.sh" && nvm use 14 && nvm current && yarn install && DISTRIBUTION_ID=$(terraform state pull | jq -r '.resources[] | select( .type == "aws_cloudfront_distribution" ) | .instances[0].attributes.id') S3_BUCKET_NAME=${
        this.props.env.client
      }-${this.props.env.product}-${this.props.env.name} ROOT_URL=https://${
        this.props.env.envUrl
      } ${
        ['appwfv', 'appwfv-ce'].includes(this.props.env.product)
          ? `yarn run deploy:staging:generic:${this.props.env.client}`
          : ''
      }${this.props.env.product === 'appwfv-ce' ? ':ce' : ''} ${
        ['portal-ui', 'incentivos-ui'].includes(this.props.env.product)
          ? `API_SERVER_URL=https://${this.props.env.portalServerHost} yarn run deploy:homolog`
          : ''
      }`;
    }
    this.generatorDataObject = {
      ...this.generatorDataObject,
      ...JSON.parse(this.state.generatorData)
    };
    this.generatorDataObject.releaseName = `${this.props.env.client}-${this.props.env.product}-${this.props.env.name}`;
    this.generatorDataObject.branchName = this.props.env.branch;
    if (isKubernetes(this.props.env.product, this.state.defaults.products)) {
      this.generatorDataObject.turnOnAt = this.props.env.turnOnAt;
      this.generatorDataObject.turnOffAt = this.props.env.turnOffAt;
    }
    if (!this.generatorDataObject.url) {
      this.generatorDataObject.url = this.props.env.envUrl;
    }
    let gData = JSON.parse(this.state.generatorData);
    let generatorDataObjectString = JSON.stringify(
      this.generatorDataObject,
      null,
      2
    );
    if (
      !gData ||
      gData.branchName !== this.generatorDataObject.branchName ||
      gData.turnOffAt !== this.generatorDataObject.turnOffAt ||
      gData.turnOnAt !== this.generatorDataObject.turnOnAt
    ) {
      this.setState({ generatorData: generatorDataObjectString }, () =>
        this.save()
      );
      return;
    }
    this.setState({ generatorData: generatorDataObjectString });
  }

  cancel() {
    this.setState({ edit: false });
  }

  save() {
    this.setState({ edit: false }, () => {
      if (this.state.generatorData != null && this.state.generatorData !== '') {
        try {
          JSON.parse(this.state.generatorData);
        } catch (e) {
          alert(jsonSyntaxInvalidError);
          return;
        }
      }
      this.props.setLoading(true);
      this.props.updateEnvironment(
        this.props.env,
        'generatorData',
        this.state.generatorData,
        () => this.props.setLoading(false)
      );
    });
  }
}

export default JenkinsConfigurator;
