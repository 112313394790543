import { LOGIN, LOGOUT } from '../constants/ActionTypes'
import {defineState} from 'redux-localstore'
import { logout } from '../services/auth'

const defaultState = {}

const initialState = defineState(defaultState)('session')

export default function session(state = initialState, action) {
  switch (action.type) {
    case LOGIN:
      return action.token

    case LOGOUT:
      logout();
      return {}

    default:
      return state
  }
}
