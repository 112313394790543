import React, { useState } from 'react'
import './EnvironmentTextInput.scss'

const EnvironmentTextInput = ({id, label, onChange, value, validation={valid: true}, editMode=false, onBlur=()=>{}, ...props}) => {
  const [editing, setEditing] = useState(false);

  const save = (e) => {
    setEditing(!editing);
    onBlur(e);
  }

  return (
    <span {...props}>
      { (editing || !editMode)
          ?
          <span className={`env-input ${validation.valid ? '': 'env-error'}`}>
            <input id={id} 
              onChange={onChange}
              onBlur={save}
              onKeyPress={(e) => {if (e.key === 'Enter') {save(e)}}}
              placeholder={' '}
              value={value}
              {...props}
            />
            <label htmlFor={id}>
              {label} 
            </label>
            <span className='message'>{validation.valid ? '': `*${validation.message}`}</span>
          </span>
          :
          <span onClick={() => setEditing(!editing)}>
            {value}
            <img alt='' src='edit_logo.png' className='smallLogo editLogo' />
          </span>
      }
    </span>
  )
}

export default EnvironmentTextInput;
