import { combineReducers } from 'redux'
import environments from './environments'
import activeFilters from './activeFilters'
import session from './session'

const rootReducer = combineReducers({
  environments,
  activeFilters,
  session,
})

export default rootReducer
