import React, { useState } from 'react';
import './Select.scss'

const Select = ({id, label, validation={valid: true}, handleChange, children, ...props}) => {
    const [value, setValue] = useState(props.value);

    return <span className={`env-select ${validation.valid ? '': 'env-error'}`}>
        <select id={id}
                {...props}
                onChange={(e) => {
                    setValue(e.target.value);
                    handleChange(e);
                }}
                defaultValue={''}>
            {children}
        </select>
        <input type="hidden" value={value}>
        </input>
        <label htmlFor={id}>{label}</label>
        <span className='message'>{validation.valid ? '': `*${validation.message}`}</span>
    </span>
}

export default Select;
