import {
  ADD_ENVIRONMENT,
  DELETE_ENVIRONMENT,
  EDIT_ENVIRONMENT,
  RECEIVE_ENVIRONMENTS,
  REQUEST_ENVIRONMENTS,
} from '../constants/ActionTypes'

const initialState = {
  loading: false,
  firstLoad: true,
  byEnvUrl: {},
  envUrls: []
};

export default function environments(state = initialState, action) {
  switch (action.type) {
    case REQUEST_ENVIRONMENTS:
      return {
        loading: true,
        firstLoad: false,
        defaults: state.defaults,
        envUrls: state.envUrls,
        byEnvUrl: state.byEnvUrl,
      };
    case RECEIVE_ENVIRONMENTS:
      return {
        loading: false,
        firstLoad: false,
        defaults: action.defaults,
        envUrls: action.environments.map(e => e.envUrl),
        byEnvUrl: action.environments.reduce((obj, e) => ({...obj, [e.envUrl]: e}), {})
      }
    case ADD_ENVIRONMENT:
      let envUrlComputed = action.environment.subdomain ? `${action.environment.subdomain}.${action.environment.domain}` : action.environment.domain
      return {
        loading: false,
        firstLoad: false,
        defaults: state.defaults,
        envUrls: [
          envUrlComputed,
          ...state.envUrls          
        ],
        byEnvUrl: {...state.byEnvUrl, 
          [envUrlComputed]: {
            ...action.environment,
            envUrl: envUrlComputed
          }
        }
      }

    case DELETE_ENVIRONMENT:
      delete state.byEnvUrl[action.envUrl]

      return {
        loading: false,
        firstLoad: false,
        defaults: state.defaults,
        envUrls: state.envUrls.filter(envUrl =>
          envUrl !== action.envUrl
        ),
        byEnvUrl: state.byEnvUrl
      }

    case EDIT_ENVIRONMENT:
      return {
        loading: false,
        firstLoad: false,
        defaults: state.defaults,
        envUrls: state.envUrls,
        byEnvUrl: {...state.byEnvUrl, [action.envUrl]: action.environment}
      }

    default:
      return state
  }
}
