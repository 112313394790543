import * as types from '../constants/ActionTypes';
import { refresh } from '../services/auth';
import { formatDateTimePtBr } from '../services/Utils';
import { operationTimeoutError, tryAgainError } from '../components/Bundle';

export const addEnvironment = (environment) => ({
  type: types.ADD_ENVIRONMENT,
  environment
});
export const removeEnvironment = (envUrl) => ({
  type: types.DELETE_ENVIRONMENT,
  envUrl
});
export const editEnvironment = (envUrl, environment) => ({
  type: types.EDIT_ENVIRONMENT,
  envUrl,
  environment
});
export const addFilter = (key, term, keyLabel, termLabel) => ({
  type: types.ADD_FILTER,
  key,
  term,
  keyLabel,
  termLabel
});
export const deleteFilter = (id) => ({ type: types.DELETE_FILTER, id });
export const clearFilters = () => ({ type: types.CLEAR_FILTERS });
export const login = (token) => ({ type: types.LOGIN, token });
export const logout = () => ({ type: types.LOGOUT });

export const requestEnvironments = () => {
  return {
    type: types.REQUEST_ENVIRONMENTS
  };
};

export const receiveEnvironments = (json, defaults) => {
  return {
    type: types.RECEIVE_ENVIRONMENTS,
    environments: json,
    defaults: defaults
  };
};

export const receiveBuildStatus = (envUrl, buildStatus) => {
  return {
    type: types.RECEIVE_BUILD_STATUS,
    buildStatus: buildStatus,
    envUrl: envUrl
  };
};

const getToken = (dispatch, getState) => {
  const idToken = getState().session.signInUserSession.idToken;

  if (new Date() > new Date(parseInt(idToken.payload.exp + '000'))) {
    refresh(getState().session, (session) => dispatch(login(session)));
  }

  return idToken.jwtToken;
};

const defaultBuildStatus = {};
const processBuildStatus = (buildStatus) => {
  if (buildStatus && buildStatus.result) {
    const causeAction = buildStatus.actions.find(
      (action) => action._class === 'hudson.model.CauseAction'
    );
    if (!causeAction) {
      return defaultBuildStatus;
    }
    return {
      result: buildStatus.result,
      timestamp: buildStatus.timestamp,
      url: `${buildStatus.url}console`,
      date: formatDateTimePtBr(buildStatus.timestamp),
      description: causeAction.causes[0].shortDescription
    };
  }

  return defaultBuildStatus;
};

export const fetchEnvironments = () => {
  return (dispatch, getState, { api }) => {
    dispatch(requestEnvironments());
    const jwtToken = getToken(dispatch, getState);
    return fetch(`${api}/environments`, {
      headers: new Headers({ Authorization: jwtToken })
    })
      .then((response) => response.json())
      .then(async (json) => {
        const result = await json.map(async (env) => {
          const response = await fetch(`${api}/environments/${env.envUrl}`, {
            headers: new Headers({ Authorization: jwtToken })
          });
          const data = await response.json();
          env['lastBuild'] = processBuildStatus(data.lastBuild);
          const generatorData = JSON.parse(env.generatorData);
          const cost = () => {
            if (!env.generatorData || env.generatorData === '{}') {
              return 0;
            }

            const postgresCpu = generatorData.postgresDisabled
              ? 0
              : parseFloat(generatorData.postgresCpu) || 0.5;

            const postgresMem = generatorData.postgresDisabled
              ? 0
              : parseFloat(generatorData.postgresMem) || 1024;

            const mongoCpu = generatorData.mongoDb
              ? parseFloat(generatorData.mongoDb.cpuLimit) || 0.1
              : 0;

            const mongoMem = generatorData.mongoDb
              ? parseFloat(generatorData.mongoDb.memLimit) || 256
              : 0;

            const costByCpu =
              ((parseFloat(generatorData.appServerCpuLimit) || 0.5) +
                postgresCpu +
                mongoCpu) *
              720 *
              0.0765;

            const costByMem =
              (((parseFloat(generatorData.appServerMemLimit) || 1024) +
                postgresMem +
                mongoMem) /
                1024) *
              720 *
              0.019125;

            const diskCost = () => {
              const mongoDbCost = generatorData.mongoDb
                ? parseFloat(generatorData.mongoDb.awsEBSSize.slice(0, -1)) *
                  0.19
                : 0;

              if (!generatorData.awsEBSSize) {
                return mongoDbCost;
              }
              const postgresCost =
                parseFloat(generatorData.awsEBSSize.slice(0, -1)) + mongoDbCost;
              if (generatorData.volumeType === 'io1') {
                return (
                  postgresCost * 0.238 +
                  parseFloat(generatorData.volumeIops) * 0.091
                );
              }
              if (generatorData.volumeType === 'gp2') {
                return postgresCost * 0.19;
              }

              return postgresCost * 0.12;
            };

            const discount = generatorData.useSpot ? 0.3 : 0.6;

            if (costByCpu > costByMem) {
              return ((costByCpu * discount + diskCost()) * 1.1).toFixed(2);
            }

            return ((costByMem * discount + diskCost()) * 1.1).toFixed(2);
          };
          env['cost'] = env.kubernetes ? cost() : 0;
          env = { ...env, ...JSON.parse(env.generatorData) };
          return env;
        });
        Promise.all(result).then((values) => {
          fetch(`${api}/environments/defaults`, {
            headers: new Headers({ Authorization: jwtToken })
          })
            .then((response) => response.json())
            .then((json) => dispatch(receiveEnvironments(values, json)));
        });
      })
      .catch((error) => console.log(error));
  };
};

export const createEnvironment = (env) => {
  return (dispatch, getState, { api }) => {
    dispatch(requestEnvironments());
    const jwtToken = getToken(dispatch, getState);
    return fetch(`${api}/environments`, {
      method: 'POST',
      body: JSON.stringify(env),
      headers: new Headers({ Authorization: jwtToken })
    })
      .then((response) => response.json())
      .then((json) => dispatch(addEnvironment(json)))
      .catch(async (error) => console.log(error));
  };
};

export const updateEnvironment = (env, field, value, callback = () => {}) => {
  return (dispatch, getState, { api }) => {
    const callbackAfterRequest = (json) =>
      dispatch(
        editEnvironment(env.envUrl, {
          ...json,
          ...JSON.parse(json.generatorData),
          [field]: value
        })
      );
    fetchByMethod(
      dispatch,
      getState,
      api,
      env,
      {
        field: field,
        value: value
      },
      callbackAfterRequest,
      callback
    );
  };
};

export const deleteEnvironment = (env, callback = () => {}) => {
  return (dispatch, getState, { api }) => {
    const callbackAfterRequest = () => dispatch(removeEnvironment(env.envUrl));
    fetchByMethod(
      dispatch,
      getState,
      api,
      env,
      env,
      callbackAfterRequest,
      callback,
      'DELETE'
    );
  };
};

const fetchByMethod = (
  dispatch,
  getState,
  api,
  env,
  body,
  callbackAfterRequest,
  callback,
  method = 'PUT'
) => {
  dispatch(requestEnvironments());
  const jwtToken = getToken(dispatch, getState);
  return fetch(`${api}/environments/${env.envUrl}`, {
    method: method,
    body: JSON.stringify(body),
    headers: new Headers({ Authorization: jwtToken })
  })
    .then((response) => {
      if (!response.ok) {
        if (response.status === 504) {
          throw new Error('timeout');
        }

        throw new Error(response.text());
      }
      callback();
      return response.json();
    })
    .then((json) => callbackAfterRequest(json))
    .catch(async (error) => {
      if (error.message === 'timeout') {
        let retries = 0;
        let updated = false;
        while (!updated && retries <= 20) {
          console.log('updating...');
          const checkFunction = method === 'PUT' ? checkUpdated : checkDeleted;
          updated = await checkFunction(env, api, getState, dispatch);
          retries++;
          await sleep(3000 * retries);
        }
        if (!updated) {
          alert(operationTimeoutError);
        }
      } else {
        alert(tryAgainError(error));
      }
      callback();
    });
};

const checkUpdated = async (env, api, getState, dispatch) => {
  try {
    const jwtToken = getToken(dispatch, getState);
    const json = await fetch(`${api}/environments/${env.envUrl}`, {
      headers: new Headers({ Authorization: jwtToken })
    }).then((response) => response.json());

    if (json.modifiedDate > env.modifiedDate) {
      dispatch(
        editEnvironment(env.envUrl, {
          ...json,
          ...JSON.parse(json.generatorData)
        })
      );
      return true;
    }
  } catch (error) {
    return false;
  }

  return false;
};

const checkDeleted = async (env, api, getState, dispatch) => {
  try {
    const jwtToken = getToken(dispatch, getState);
    const json = await fetch(`${api}/environments/${env.envUrl}`, {
      headers: new Headers({ Authorization: jwtToken })
    }).then((response) => {
      if (!response.ok) {
        if (response.status === 404) {
          throw new Error('not found!');
        }

        throw new Error(response.text());
      }
      return response.json();
    });

    if (json) {
      return false;
    }
  } catch (error) {
    if (error.message === 'not found!') {
      dispatch(removeEnvironment(env.envUrl));
      return true;
    }
    return false;
  }

  return false;
};

const sleep = (milliseconds) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
};

export const getProfile = (session) => {
  if (session && 'signInUserSession' in session) {
    return session.signInUserSession.idToken.payload;
  }

  return {};
};
