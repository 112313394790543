import { connect } from 'react-redux'
import { addFilter, deleteFilter } from '../actions'
import FilterBar from '../components/FilterBar'

const mapStateToProps = (state) => ({
  filters: state.activeFilters,
})

const mapDispatchToProps = (dispatch) => ({
  addFilter: (key, term, keyLabel, termLabel) => {
    dispatch(addFilter(key, term, keyLabel, termLabel))
  },
  deleteFilter: (filterKey) => {
    dispatch(deleteFilter(filterKey))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterBar)
