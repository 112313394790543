export const cronExpressionTooltip = 'Cron expression: MINUTE HOUR DOM MONTH DOW => MINUTE (0–59) | HOUR (0–23) | DOM: day of month (1–31) | MONTH (1–12) | DOW: Day of week (0–7) => 0 and 7 are Sunday';
export const invalidEnvironmentsError = 'There is some invalid environments. Please, fix them before save.';
export const productDefaultsError = 'Product does not exist in defaults.json!';
export const clientDefaultsError = 'Client does not exist in defaults.json!';
export const productClientDefaultsError = 'Product does not exist for Client inside defaults.json!';
export const jsonSyntaxInvalidError = 'JSON syntax is invalid.';
export const operationTimeoutError = 'Operation timed out! Try again!';

export const blankError = (field) => `${field} cannot be blank.`;
export const invalidError = (field) => `${field} is invalid.`;
export const cronExpressionError = (field) => `${field} should have a valid cron expression.`;
export const lowercaseNumberHyphenError = (field) => `${field} should have lower case letters, numbers and hyphens only.`;
export const lowercaseNumberDotsHyphenError = (field) => `${field} should have lower case letters, dots, numbers and hyphens only.`;
export const tryAgainError = (error) => `Error try again!: ${error}`;
export const noCustomerConfiguredDefaultsError = (client) => `There is no customer ${client} configured in defaults.json`;
